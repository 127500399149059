<template>
	<v-dialog
        v-model="showCustomerDialog"
        activator="parent"
        width="auto"
        max-width="1000"
      >
        <v-card>
          <v-card-text>
            {{ showCustomerDialog }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    data: () => ({
		loadingColorSave: false,
	}),

	props: ['showCustomerDialog'],

	methods: {}
};
</script>

<style>
</style>